import galleryImg1 from "../../assets/images/gallery/gallery-img1.png";
import galleryImg2 from "../../assets/images/gallery/gallery-img2.png";
import galleryImg3 from "../../assets/images/gallery/gallery-img3.png";
import galleryImg4 from "../../assets/images/gallery/gallery-img4.png";
import galleryImg5 from "../../assets/images/gallery/gallery-img5.png";
import galleryImg6 from "../../assets/images/gallery/gallery-img6.png";

export const galleryImages = [
  { src: galleryImg1 },
  { src: galleryImg2 },
  { src: galleryImg3 },
  { src: galleryImg4 },
  { src: galleryImg5 },
  { src: galleryImg6 },
];

export const menuItems1 = [
  {
    name: "Bruschetta",
    price: "$11.96",
    description: "Toasted artisan bread, topped with marinated tomatoes, basil, garlic, drizzled with olive oil.",
  },
  {
    name: "Caesar Salad",
    price: "$15.00",
    description: "Crisp romaine lettuce, shaved Parmesan, crunchy croutons, tossed in a rich Caesar dressing.",
  },
  {
    name: "Chicken Wings",
    price: "$18.00",
    description: "Crispy chicken wings, choice of sauce, served with celery and a creamy blue cheese dip.",
  },
  {
    name: "Nachos",
    price: "$16.00",
    description: "Hearty nachos topped with melted cheese, jalapeños, salsa, with guacamole or chili on the side.",
  },
  {
    name: "Garlic Bread",
    price: "$9.00",
    description: "Freshly baked bread slathered in garlic butter, toasted until golden, ideal for dipping.",
  },
];

export const menuItems2 = [
  {
    name: "Dumplings",
    price: "$27.96",
    description: "Pork and cabbage in thin dough, steamed to perfection. A classic dish with deep flavors.",
  },
  {
    name: "Kung Pao Chicken",
    price: "$42.00",
    description: "Spicy stir-fry with chicken, peanuts, vegetables in a rich sauce. Sweet, tangy with a heat kick.",
  },
  {
    name: "Beef Noodle Soup",
    price: "$35.00",
    description: "Savory beef broth with tender beef, noodles, and vegetables. A comforting, nourishing meal.",
  },
  {
    name: "Spring Rolls",
    price: "$22.00",
    description: "Crispy rolls with vegetables/meat, served with dipping sauce. A delightful appetizer.",
  },
  {
    name: "Mapo Tofu",
    price: "$37.00",
    description: "Soft tofu in a spicy, savory sauce with minced pork. Bold, complex flavors in every bite.",
  },
];

export const menuItems3 = [
  {
    name: "Margherita",
    price: "$12.95",
    description: "A timeless classic with a rich tomato sauce, topped with fresh mozzarella, aromatic basil leaves, and a final drizzle of extra virgin olive oil.",
  },
  {
    name: "Pepperoni",
    price: "$14.95",
    description: "Spicy pepperoni slices layered over a gooey mozzarella and rich tomato base, seasoned with a blend of Italian herbs for extra flavor.",
  },
  {
    name: "Hawaiian",
    price: "$15.95",
    description: "A delightful mix of sweet pineapple and savory ham, combined with stretchy mozzarella and our signature tomato sauce for a tropical twist.",
  },
  {
    name: "Veggie Delight",
    price: "$13.95",
    description: "A garden of bell peppers, sweet onions, earthy mushrooms, ripe olives, and juicy tomatoes, all atop a classic tomato sauce and mozzarella cheese base.",
  },
  {
    name: "BBQ Chicken",
    price: "$16.95",
    description: "Tender grilled chicken, caramelized red onions, and fresh cilantro, all smothered in a smoky BBQ sauce on a bed of melted mozzarella cheese.",
  },
];

export const menuItems4 = [
  {
    name: "Cheesecake",
    price: "$7.95",
    description: "Creamy cheesecake on a graham cracker crust, served with a berry compote for a sweet and tangy finish.",
  },
  {
    name: "Tiramisu",
    price: "$8.95",
    description: "Layered Italian dessert with mascarpone cheese, espresso-soaked ladyfingers, dusted with cocoa powder for a rich and indulgent treat.",
  },
  {
    name: "Chocolate Lava Cake",
    price: "$9.95",
    description: "Warm, gooey chocolate cake with a molten chocolate center, served with vanilla ice cream on the side for a decadent experience.",
  },
  {
    name: "Apple Pie",
    price: "$6.95",
    description: "Classic American dessert with spiced apple filling in a flaky pastry crust, served warm with a scoop of vanilla ice cream.",
  },
  {
    name: "Gelato",
    price: "$5.95",
    description: "Italian-style ice cream, available in various flavors, known for its smooth texture and intense flavor. A refreshing end to any meal.",
  },
];

export const menuItems5 = [
  {
    name: "Espresso",
    price: "$3.00",
    description: "A strong, concentrated coffee brewed by forcing hot water through finely-ground coffee beans.",
  },
  {
    name: "Latte",
    price: "$4.50",
    description: "Smooth espresso mixed with steamed milk and topped with a light layer of milk foam.",
  },
  {
    name: "Green Tea",
    price: "$2.50",
    description: "Freshly brewed green tea, known for its antioxidants, offering a delicate and refreshing taste.",
  },
  {
    name: "Smoothie",
    price: "$5.00",
    description: "A blend of fresh fruits and yogurt, creating a healthy, flavorful drink. Options vary seasonally.",
  },
  {
    name: "Craft Beer",
    price: "$6.00",
    description: "A selection of local and imported craft beers, showcasing unique flavors and brewing techniques.",
  },
];

export const menuSections = [
  { id: "nav-starter-tab", label: "starters", menuItems: menuItems1 },
  { id: "nav-chinese-tab", label: "Chinese", menuItems: menuItems2  },
  { id: "nav-pizza-tab", label: "Pizza", menuItems: menuItems3  },
  { id: "nav-dessert-tab", label: "Dessert", menuItems: menuItems4  },
  { id: "nav-drink-tab", label: "Drink", menuItems: menuItems5  },
];

export const slider = [
  "More Than Just Cooking: A Journey of Flavors",
  "Crafting Unique Sensory Experiences",
  "Every Dish Has Its Own Story",
  "The Fine Art of Select Ingredients"
];


