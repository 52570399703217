import chefImg1 from "../../assets/images/best-chef/chef-img1.png";
import chefImg2 from "../../assets/images/best-chef/chef-img2.png";
import chefImg3 from "../../assets/images/best-chef/chef-img3.png";
import chefImg4 from "../../assets/images/best-chef/chef-img4.png";
import chefImg5 from "../../assets/images/best-chef/chef-img5.png";
import chefImg6 from "../../assets/images/best-chef/chef-img6.png";

export const chefs = [
    { name: 'R. JONATHAN', image: chefImg1 },
    { name: 'A. BARTHAJA', image: chefImg2 },
    { name: 'R. JONATHAN', image: chefImg3 },
    { name: 'R. JONATHAN', image: chefImg4 },
    { name: 'R. JONATHAN', image: chefImg5 },
    { name: 'D. MORIS', image: chefImg6 },
  ];