
import blogImg1 from "../../assets/images/blog/blog-img1.png";
import blogImg2 from "../../assets/images/blog/blog-img2.png";
import blogImg3 from "../../assets/images/blog/blog-img3.png";
import blogImg4 from "../../assets/images/blog/blog-img4.png";
import blogImg5 from "../../assets/images/blog/blog-img5.png";
import blogImg6 from "../../assets/images/blog/blog-img6.png";

export  const blogs = [
    {
      date: 'JUNE 02, 2022',
      title: 'Simplicity is complex',
      content: 'Nunc vehicula massa vitae felis suscipit, et semper ligula eleifend. Cras neque justo, congue eget congue sit amet, sodales...',
      image: blogImg1,
    },
    {
      date: 'JUNE 01, 2022',
      title: 'Design is Process',
      content: 'Nunc vehicula massa vitae felis suscipit, et semper ligula eleifend. Cras neque justo, congue eget congue sit amet, sodales...',
      image: blogImg2,
    },
    {
      date: 'JUNE 01, 2022',
      title: 'Aesthetic is a decision',
      content: 'Nunc vehicula massa vitae felis suscipit, et semper ligula eleifend. Cras neque justo, congue eget congue sit amet, sodales...',
      image: blogImg3,
    },
    {
      date: 'MARCH 30, 2022',
      title: 'Supporting food flavors',
      content: 'Nunc vehicula massa vitae felis suscipit, et semper ligula eleifend. Cras neque justo, congue eget congue sit amet, sodales...',
      image: blogImg4,
    },
    {
      date: 'JUNE 16, 2022',
      title: 'Business Breakfast',
      content: 'Nunc vehicula massa vitae felis suscipit, et semper ligula eleifend. Cras neque justo, congue eget congue sit amet, sodales...',
      image: blogImg5,
    },
    {
      date: 'MAY 25, 2022',
      title: '6 Ingredient Vegan Cinnamon Rolls',
      content: 'Nunc vehicula massa vitae felis suscipit, et semper ligula eleifend. Cras neque justo, congue eget congue sit amet, sodales...',
      image: blogImg6,
    },
    // Add other blog objects here
  ];